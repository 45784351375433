@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";


.select-target-item {
  .counter {
    margin-right: 10px;
  }

  .inquest-icon {
    margin-right: 8px;
  }
}


.report-view-page {
    padding: 80px 60px;
    width: 100%;
    height: 100%;

    // mobile
    @media (max-width: $md-screen) {
      padding: 80px 24px;      
    }

    .label-select {
      margin-bottom: 10px;
    }

    .report-title-info {
        .case-name-tile {
            @extend .font-family-1;

            font-size: 16px;
            font-weight: 400;
            padding: 25px 0;
        }

        .ant-col > div {
            margin-bottom: 8px;
        }
        
        .collapse-report-context {
          .report-context-info > div {
            margin-bottom: 5px;
            label {
              font-weight: 600;
            }
          }

          .docs-products-info {
            text-transform: uppercase !important;
          }

          .ant-collapse-item {
            .ant-collapse-header {
              padding: 0 !important;
              margin: 0 !important;
            }

            .ant-collapse-header-text {
              color: #1d96d7;
              cursor: pointer;
              font-weight: 600;
            }

            .ant-collapse-expand-icon {
              display: none;
            }
          }
        }

        .collapse-report-files {
          .ant-collapse-item {
            .ant-collapse-header-text {
              color: #1d96d7;
              cursor: pointer;
              font-weight: 600;
            }
          }
        }
    }

    .report-targets-info {
        padding: 60px 0 30px 0;


        .ant-select {
            width: 100%;
            max-width: 700px;
            
            .ant-select-selector {
              border-radius: 0 !important;
              
              .ant-select-selection-search input {
                border-radius: 0 !important;
              }
            }

            .ant-select-selector, .ant-select-selection-item {
              height: 40px;
            }
    
            @media (max-width: $md-screen) {
              max-width: none;
            }
    
            .ant-select-selection-item {
              @extend .font-normal-14-600;
              color: colors.$black-2;
              position: relative;
              top: 5px;
            }
    
            .ant-select-selection-placeholder {
              @extend .font-normal-14-600;
              color: colors.$blue-1;
              position: relative;
              top: 5px;
            }
          }
    }

    .report-view-data {
        
    }
}