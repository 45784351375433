@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.card-counter-score {
    background-color: #EEEEEE;
    border-radius: 8px;
    padding: 8px;
    min-height: 118px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;

    &.transparent {
        opacity: 0.4;
    }

    // mobile
    @media (max-width: $md-screen) {
        * { font-size: 10px !important; }

        .title {
            font-size: 12px !important;
        }

        .qtd-value {
            font-size: 10px !important;
        }

        .footer-card .link {
            font-size: 8px !important;
        }

        .footer-card .total-value {
            font-size: 14px !important;
        }
    }

    .title {
        font-size: 16px !important;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .progress-bar-container {
        height: 15px;
        width: 100%;
        margin-bottom: 10px;
    }

    .score-point {
        svg {
            width: 15px !important;
            height: 15px !important;
        }
        top: -25px !important;
    }

    .footer-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .link {
            font-size: 14px !important;
            text-decoration: underline !important;
            color: rgb(0, 148, 222);
            font-weight: 500;
        }

        .total-value {
            font-size: 20px !important;
            font-weight: 500;
        }
    }
}