@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.target-view-page, .doc-view-page {

    .ant-tabs-nav-operations {
        background-color: #1677FF;
        
        button {
            color: white !important;
        }

        &:hover {
            background-color: white;

            button {
                color: #1677FF !important;
            }
        }
    }

    .carousel {
        margin-top: 10px;
        > .slick-slider {
            // position: relative;
            // top: -50px;

            ul {
                position: absolute;
                top: -18px;

                li {
                    background-color: white;
                    border: 1px solid colors.$blue-1;

                    &.slick-active  {
                        background-color: white;
                        border: 1px solid colors.$blue-1;

                        button{
                            background-color: colors.$blue-1 !important;
                            color: colors.$blue-1 !important;
                        }
                    }
                }
            }
        }


        .carousel-indicators {
            position: absolute; 
            top: -25px;
            height: 25px;
        }

        .carousel-control-next, .carousel-control-prev {
            width: 2%;
            
            // mobile
            @media (max-width: $sm-screen) {
                width: 5%;
            }
    
            // tablet
            @media (max-width: $md-screen) {
                width: 3%;
            }
        }
    }

.target-view-page .ant-tabs-content, .carousel {
    display: none;
}
    
.ant-tabs-content, .carousel {
    background-color: white;

    .MuiPaper-root {
        border-radius: 0;
        box-shadow: none;
    }
   
    .section-title {
        background-color: white !important;
        padding: 10px;
        border-bottom: 1px solid;

        span:first-child {
            margin-right: 10px;
            margin-left:  10px;

            svg {
                color: colors.$blue-1;
            }
        }

        span:last-child {
            font-size: 16px;
            font-weight: normal;
            margin: 0;
            padding: 0;
            font-family: 'Roboto', sans-serif;
            color: black;
        }
    }

    // mobile
    @media (max-width: $md-screen) {
        .section-title span:last-child {
            font-size: 14px;
        }
    }
    
    .section-content {
        background-color: white !important;
        padding: 15px 25px;
        min-height: 250px;
        
        .service-label-value {
            min-height: 60px;
            margin-bottom: 5px;

            label {
                font-size: 14px;
                font-weight: 600;
            }

            &.inline > *{
                display: inline;

                &:first-child{
                    margin-right: 5px;
                }
            }

            ul {
                padding-left: 15px !important;
                
                li, ol {
                    margin: 0 !important;
                    padding: 0 !important;
                    margin-bottom: 10px !important;
                    
                    &:last-child {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .info-message {
            width: 100%;
            text-align: center;
            margin: 25px 0;
      
            p {
              // @extend .font-family-2;
              padding: 15px;
              border-radius: 5px;
              display: inline-block;
              max-width: 50%;
              background-color: colors.$gray-5;
              line-height: 20px;
      
              @media (max-width: $md-screen) {
                margin: 0;
                max-width: 100%;
              }

              span.highcolor {
                color: colors.$red-0;
              }
            }
          }

        
        .ant-table {
            overflow-x: scroll;

            th.ant-table-cell {
                padding: 5px 10px !important;
                font-size: 14px;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                background-color: colors.$gray-6;
                border-radius: 0px !important;

                &:first-child {
                    border-radius: 0px !important;
                }
            }

            ul {
                padding-left: 15px !important;

                li, ol {
                    margin: 0 !important;
                    padding: 0 !important;
                    margin-bottom: 10px !important;
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

     // mobile
     @media (max-width: $md-screen) {
        .section-content {
            .service-label-value {

                label {
                    font-size: 12px;
                }
            }

            .ant-table {
                th.ant-table-cell {
                    font-size: 10px;
                }
            }
        }
    }
}



// widescreen
@media (min-width: $md-screen) {
    .target-view-page { 
        .ant-tabs, .ant-tabs-content {
            display: block;
        }

        .carousel {
            display: none;
        }
    }
}

// tablet/mobile
@media (max-width: $md-screen) { 
    .target-view-page {
        .ant-tabs, .ant-tabs-content {
            display: none;
        }

        .carousel {
            display: block;
        }
    }
}

}